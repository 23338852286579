import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const Form = styled.form`
  width: ${getSpacing(40)};
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${getSpacing(2)};
`;

export const BackendMessage = styled.p<{ isError: boolean }>`
  width: ${getSpacing(40)};
  color: ${(props) => (props.isError ? colors.red : colors.pink)};
  ${font.size14}
`;
