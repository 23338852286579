import React, { ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { fetchOrCreateUser } from 'clients/users/client';
import { AxiosError } from 'axios';
import AuthenticationError from 'pages/Errors/AuthenticationError';

interface Props {
  children: ReactNode;
}

const UserProvider: React.FC<Props> = ({ children }) => {
  const userQuery = useQuery({
    queryKey: [reactQueryKeys.user],
    queryFn: fetchOrCreateUser,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  /*
  We must handle unexpected errors here
  - because 401 and 403 will not redirect to AuthenticationError page
    because handleAuthorizationError (in clients/client) doesn't have the navigate function yet
    because the BrowserRouter has not rendered yet.
  - other errors (500) must be handled too because we don't want to access the application if
    the user cannot be retrieved or created.
  */
  if (userQuery.isError) {
    if (
      userQuery.error instanceof AxiosError &&
      userQuery.error.response?.status === 409
    ) {
      return (
        <AuthenticationError error="Could not create user in ROSE application because a user with the same characteristics already exists" />
      );
    }

    return <AuthenticationError />;
  }

  if (!userQuery.isSuccess) {
    return <></>;
  }

  return <>{children}</>;
};

export default UserProvider;
