import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Subcategory } from 'clients/projectVersions/type';
import Checkbox from 'components/Checkbox';
import Title from 'components/Title';
import { EnrichedProject, ProjectRoles } from 'clients/projects/type';

import {
  Container,
  Grid,
  HeaderCell,
  NumberCell,
  TextCell,
  TextHeaderCell,
} from './SubcategoryDetails.style';
import Comment from './components/Comment/Comment';
import DoneCheckbox from './components/DoneCheckbox';
import TargetCheckbox from './components/TargetCheckbox';

interface Props {
  subcategory: Subcategory;
  projectVersionId: string;
  project: EnrichedProject;
}

const SubcategoryDetails: React.FC<Props> = ({
  subcategory,
  projectVersionId,
  project,
}) => {
  return (
    <Container>
      <Title>{subcategory.name}</Title>
      <Grid>
        <TextHeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.criteria" />
        </TextHeaderCell>
        <HeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.weight" />
        </HeaderCell>
        <HeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.done" />
        </HeaderCell>
        <HeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.run" />
        </HeaderCell>
        <HeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.target" />
        </HeaderCell>
        <TextHeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.explanations" />
        </TextHeaderCell>
        <TextHeaderCell>
          <FormattedMessage id="project.category-tab.subcategory-details.comment" />
        </TextHeaderCell>
        {subcategory.criterias.map((criteria) => (
          <Fragment key={criteria.id}>
            <TextCell>{criteria.description}</TextCell>
            <NumberCell>{Number(criteria.weight)}</NumberCell>
            <NumberCell>
              <DoneCheckbox
                achievement={criteria.achievement}
                projectVersionId={projectVersionId}
                isEditable={project.currentUserRole !== ProjectRoles.VIEWER}
              />
            </NumberCell>
            <NumberCell>
              <Checkbox isChecked={criteria.isRunMandatory} />
            </NumberCell>
            <NumberCell>
              <TargetCheckbox
                achievement={criteria.achievement}
                projectVersionId={projectVersionId}
                isEditable={project.currentUserRole !== ProjectRoles.VIEWER}
              />
            </NumberCell>
            <TextCell>{criteria.explanation}</TextCell>
            <Comment
              initialComment={criteria.achievement.comment}
              achievementId={criteria.achievement.id}
              projectVersionId={projectVersionId}
              isEditable={project.currentUserRole !== ProjectRoles.VIEWER}
            />
          </Fragment>
        ))}
      </Grid>
    </Container>
  );
};

export default SubcategoryDetails;
