import styled from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';
import { ReactComponent as Trash } from 'assets/trash.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(4)};
`;

export const UserList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${getSpacing(24)} repeat(2, ${getSpacing(10)});
`;

export const HeaderCell = styled.div`
  padding: ${getSpacing(1)};
  border-bottom: 1px solid ${colors.gray0};
  height: ${getSpacing(5)};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.purple};
  ${font.size16};
`;

export const Cell = styled.div`
  height: ${getSpacing(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${font.size14};
`;

export const TrashButton = styled.button`
  height: ${getSpacing(3)};
  width: ${getSpacing(3)};
  border-radius: ${getSpacing(0.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${outline(2)}
`;

export const TrashIcon = styled(Trash)`
  height: ${getSpacing(2)};
  color: ${colors.gray1};
`;
