export enum ProjectRoles {
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export enum ProjectStatus {
  ACTIVE = 'active',
  FINISHED = 'finished',
}

export type UserProject = {
  userId: string;
  userEmail: string;
  projectRole: ProjectRoles;
  isLastAdminOfProject: boolean;
};

export type Project = {
  id: string;
  name: string;
  projectVersionMetadatas: ProjectVersionMetadata[];
  status: ProjectStatus;
  userProjects: UserProject[];
};

export type EnrichedProject = Project & {
  currentUserRole: ProjectRoles;
  canMigrateToVersion: string;
};

export type ProjectVersionMetadata = {
  id: string;
  version: string;
  isLatestOfProject: string;
};
