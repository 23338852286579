import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { projectPathParams } from 'Router';
import { fetchProject } from 'clients/projects/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';

import ProjectVersionWithPicker from './components/ProjectVersionWithPicker';

const ProjectPage: React.FC = () => {
  const { id } = useParams() as projectPathParams;

  const projectQuery = useQuery({
    queryKey: reactQueryKeys.project(id),
    queryFn: () => fetchProject(id),
  });

  if (!projectQuery.isSuccess) {
    return <></>;
  }

  return <ProjectVersionWithPicker project={projectQuery.data} />;
};

export default ProjectPage;
