import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { fetchProjectStats } from 'clients/admin/projects/client';

import StatsByProject from './components/StatsByProject';
import StatsForAllProjects from './components/StatsForAllProjects';
import { Container } from './TableTab.style';

const TableTab: React.FC = () => {
  const { locale } = useLocale();
  const { data: projectStats } = useQuery({
    queryKey: reactQueryKeys.adminProjects(locale),
    queryFn: () => fetchProjectStats(locale),
  });

  return (
    <Container>
      {projectStats && (
        <>
          <StatsForAllProjects projectStats={projectStats} />
          <StatsByProject projectStats={projectStats} />
        </>
      )}
    </Container>
  );
};

export default TableTab;
