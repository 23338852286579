import styled, { css } from 'styled-components';
import { getSpacing, onLargeScreen, onSmallScreen } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  column-gap: ${getSpacing(4)};

  ${onLargeScreen(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${onSmallScreen(css`
    grid-template-columns: auto;
    grid-row-gap: ${getSpacing(4)};
  `)}
`;
