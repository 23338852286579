import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Container } from './MarkdownContainer.style';

interface Props {
  content: string;
}

const MarkdownContainer: React.FC<Props> = ({ content }) => {
  return (
    <Container>
      <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
    </Container>
  );
};

export default MarkdownContainer;
