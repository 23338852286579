import React from 'react';

import { Container, Label, Input, Error } from './TextField.style';

interface Props {
  label: string;
  type: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  error?: string;
}

const TextField: React.FC<Props> = ({
  label,
  type,
  name,
  onChange,
  value,
  error,
}) => {
  return (
    <Container>
      <Label htmlFor={name} hasError={Boolean(error)}>
        {label}
      </Label>
      <Input
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        hasError={Boolean(error)}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default TextField;
