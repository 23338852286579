import React, { useEffect, useState } from 'react';

import { EnrichedProject, ProjectVersionMetadata } from 'clients/projects/type';

import ProjectVersionPage from './components/ProjectVersionPage';
import VersionPicker from './components/VersionPicker';

interface Props {
  project: EnrichedProject;
}

const ProjectVersionWithPicker: React.FC<Props> = ({ project }) => {
  const [selectedProjectVersionMetadata, setSelectedProjectVersionMetadata] =
    useState<ProjectVersionMetadata>(project.projectVersionMetadatas[0]);

  useEffect(() => {
    setSelectedProjectVersionMetadata(project.projectVersionMetadatas[0]);
  }, [project.projectVersionMetadatas]);

  return (
    <ProjectVersionPage
      projectVersionMetadata={selectedProjectVersionMetadata}
      project={project}
      versionPicker={
        <VersionPicker
          currentProjectVersion={selectedProjectVersionMetadata}
          allProjectVersions={project.projectVersionMetadatas}
          onVersionPicked={setSelectedProjectVersionMetadata}
        />
      }
    />
  );
};

export default ProjectVersionWithPicker;
