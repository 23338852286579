import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

import { ReactComponent as checkbox } from 'assets/checkbox.svg';

export const CheckboxIcon = styled(checkbox)<{ $isChecked: boolean }>`
  height: ${getSpacing(2.5)};
  color: ${({ $isChecked }) => ($isChecked ? colors.purple : colors.gray1)};
`;

export const Tooltip = styled.span`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: ${colors.white};
  background-color: ${colors.black};
  border-radius: ${getSpacing(1)};
  padding: ${getSpacing(1)};
  top: -${getSpacing(1)};
  left: 160%;
  ${font.size14};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${colors.transparent} ${colors.black} ${colors.transparent}
      ${colors.transparent};
  }
`;

export const Container = styled.div`
  position: relative;

  &:hover ${Tooltip} {
    visibility: visible;
  }
`;
