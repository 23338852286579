import {
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
} from '@auth0/auth0-spa-js';

type GetAccesTokenSilently = {
  (
    options: GetTokenSilentlyOptions & {
      detailedResponse: true;
    },
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (
    options: GetTokenSilentlyOptions,
  ): Promise<GetTokenSilentlyVerboseResponse | string>;
};

/*
This is probably an unusual way of doing with Auth0. I store the getAccessTokenSilently
Auth0 function here to be able to get the token in my calls later. 

This way I don't have to transform all my API calls into hooks.

However this could cause some bugs in future so it's to keep in mind in case of
an authentication problems.
*/
let getAccessTokenSilently: GetAccesTokenSilently | null = null;

export const addGetAccessTokenSilently = (
  getAccessTokenSilentlyFunction: GetAccesTokenSilently,
) => {
  getAccessTokenSilently = getAccessTokenSilentlyFunction;
};

export const callGetAccessTokenSilently = async (): Promise<string> => {
  if (!getAccessTokenSilently) {
    return '';
  }

  return getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_BACKEND_URL || '',
      scope: process.env.REACT_APP_AUTH0_API_SCOPE || '',
    },
  });
};
