import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import EditableCheckbox from 'components/EditableCheckbox';
import { toggleTarget } from 'clients/achievements/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { Achievement } from 'clients/projectVersions/type';
import Checkbox from 'components/Checkbox';

interface Props {
  achievement: Achievement;
  projectVersionId: string;
  isEditable: boolean;
}

const TargetCheckbox: React.FC<Props> = ({
  achievement,
  projectVersionId,
  isEditable,
}) => {
  const queryClient = useQueryClient();
  const { locale } = useLocale();

  const toggleTargetMutation = useMutation({
    mutationFn: toggleTarget,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: reactQueryKeys.projectVersion(projectVersionId, locale),
      });
    },
  });

  return isEditable ? (
    <EditableCheckbox
      toggleChecked={() => toggleTargetMutation.mutate(achievement.id)}
      isInitiallyChecked={achievement.target}
    />
  ) : (
    <Checkbox isChecked={achievement.target} />
  );
};

export default TargetCheckbox;
