import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';

export const LinkStyle = css`
  cursor: pointer;
  box-sizing: border-box;
  width: fit-content;
  color: ${colors.purple};
  border-radius: ${getSpacing(0.5)};
  padding: ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${font.size14};
  ${outline(0)}
`;

const Link = styled(ReactRouterLink)`
  ${LinkStyle};
`;

export const LinkButton = styled.button`
  ${LinkStyle};

  &:disabled {
    color: ${colors.gray1};
  }
`;

export default Link;
