import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { EnrichedProject } from 'clients/projects/type';
import Title from 'components/Title';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { deleteProject } from 'clients/projects/client';

import { Container, DeleteButton } from './DeletionSettings.style';
import { PATHS } from 'Router';

interface Props {
  project: EnrichedProject;
}

const DeletionSettings: React.FC<Props> = ({ project }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [reactQueryKeys.projects],
      }); // Fix: Pass reactQueryKeys.projects as an array
      navigate(PATHS.PROJECTS);
    },
  });

  return (
    <Container>
      <Title>
        <FormattedMessage id="project.settings-tab.deletion-settings.title" />
      </Title>
      <DeleteButton
        onClick={() => {
          if (
            window.confirm(
              intl.formatMessage({
                id: 'project.settings-tab.deletion-settings.warning',
              }),
            )
          ) {
            if (
              window.confirm(
                intl.formatMessage({
                  id: 'project.settings-tab.deletion-settings.warning2',
                }),
              )
            ) {
              deleteMutation.mutate(project.id);
            }
          }
        }}
      >
        <FormattedMessage id="project.settings-tab.deletion-settings.button" />
      </DeleteButton>
    </Container>
  );
};

export default DeletionSettings;
