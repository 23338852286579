import styled from 'styled-components';

export const Container = styled.div`
  a,
  ul,
  li,
  p {
    all: revert;
  }

  a {
    text-decoration: none;
    color: #3284ff;
  }

  ul {
    line-height: normal;
  }
`;
