import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';
import { ReactComponent as RoseLogo } from 'assets/rose.svg';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${getSpacing(6)};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${getSpacing(2)};
`;

export const TitleText = styled.h1`
  ${font.size24};
`;

export const Logo = styled(RoseLogo)`
  height: ${getSpacing(5)};
  color: ${colors.purple};
`;
