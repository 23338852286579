import React from 'react';
import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { flattenMessages } from './utils';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

export enum Locales {
  FR = 'fr',
  EN = 'en',
}

const locales = {
  [Locales.FR]: flattenMessages(frMessages),
  [Locales.EN]: flattenMessages(enMessages),
};

type LocaleContextProps = {
  setLocale: (value: React.SetStateAction<Locales>) => void;
  locale: Locales;
};

const LocaleContext = createContext<LocaleContextProps | null>(null);

type LocaleProviderProps = {
  children: ReactNode;
};

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (!context) {
    throw new Error('context must be used within an LocaleProvider');
  }

  return context;
};

const LocaleProvider: FC<LocaleProviderProps> = ({ children }) => {
  const [locale, setLocale] = useState(Locales.FR);

  return (
    <LocaleContext.Provider value={{ setLocale, locale }}>
      <IntlProvider locale={locale} messages={locales[locale]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
