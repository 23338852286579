import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Form = styled.form`
  width: ${getSpacing(40)};
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${getSpacing(3)};
`;

export const BackendError = styled.p`
  color: ${colors.red};
  ${font.size16}
`;
