import styled, { css } from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(1)};
`;

export const gridTemplateColumnsCss = css`
  grid-template-columns:
    ${getSpacing(30)}
    repeat(3, ${getSpacing(20)})
    ${getSpacing(30)}
    ${getSpacing(20)};
`;
