import { Locales } from 'providers/LocaleProvider/LocaleProvider';

export function formatDateToLocaleString(date: Date, locale: Locales) {
  if (locale === Locales.FR) {
    return date.toLocaleString('fr-FR').split(' ')[0];
  }
  return date.toLocaleString('en-EN').split(',')[0];
}

export type ValueLabel = {
  value: string | number | Date | null;
  label: string | number;
};

export function toValueLabelFormat(
  value: string | number | Date | null | undefined,
  label?: string | number | Date | null,
): ValueLabel {
  const outputLabel = label ?? value ?? '';

  return {
    value: value ?? null,
    label:
      typeof outputLabel === 'object' // Date
        ? outputLabel.toLocaleDateString()
        : outputLabel,
  };
}
