import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { generatePath, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { LinkButton } from 'components/Link/Link';
import { SecondaryButtonLink } from 'components/Button/Button';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { fetchUser } from 'clients/users/client';
import { AdminFixedTabsIds } from 'pages/Admin/utils';
import { PATHS } from 'Router';

import LocalePicker from './components/LocalePicker';
import {
  Container,
  Header,
  HeaderLeft,
  StyledLink,
  Logo,
  Title,
  HeaderRight,
  Gutter,
  Content,
} from './BaseLayout.style';

interface Props {
  title: string;
  secondHeader?: ReactNode;
  children: ReactNode;
}

const BaseLayout: React.FC<Props> = ({ title, secondHeader, children }) => {
  const { logout } = useAuth0();
  const { pathname } = useLocation();
  const isRoseAdmin = pathname.includes('/admin');
  const userQuery = useQuery({
    queryKey: [reactQueryKeys.user],
    queryFn: fetchUser,
  });

  useEffect(() => {
    document.title = `ROSE - ${title}`;
  }, [title]);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <StyledLink to={PATHS.HOME}>
            <Logo />
          </StyledLink>
          <Title>
            <span>
              <FormattedMessage id="title" />
            </span>
            <span>-</span>
            <span>{title}</span>
          </Title>
        </HeaderLeft>
        <HeaderRight>
          {userQuery.data?.isAtLeastPadokien() && (
            <SecondaryButtonLink
              to={
                isRoseAdmin
                  ? PATHS.PROJECTS
                  : generatePath(PATHS.ADMIN, {
                      tabId: AdminFixedTabsIds.DASHBOARD,
                    })
              }
            >
              <FormattedMessage
                id={isRoseAdmin ? 'go-to-app-button' : 'go-to-admin-button'}
              />
            </SecondaryButtonLink>
          )}

          <LocalePicker />
          <LinkButton onClick={() => logout()}>
            <FormattedMessage id="error.logout" />
          </LinkButton>
        </HeaderRight>
      </Header>
      {secondHeader}
      <Gutter>
        <Content>{children}</Content>
      </Gutter>
    </Container>
  );
};

export default BaseLayout;
