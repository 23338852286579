import styled from 'styled-components';
import { getSpacing, font } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(2)};
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${getSpacing(2)};
`;

export const Text = styled.p`
  ${font.size16}
`;
