import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

import { ProjectStatus } from 'clients/projects/type';

import BaseLayout from 'layout/BaseLayout';
import TextField from 'components/TextField';
import Button from 'components/Button';

import { Form, BackendError } from './ProjectsNew.style';
import { useCreateProject } from './hooks/useCreateProject';

const ProjectsNew: React.FC = () => {
  const intl = useIntl();

  const [backendError, setBackendError] = useState<string | null>(null);

  const mutation = useCreateProject(setBackendError);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-z0-9-]+$/,
        intl.formatMessage({
          id: 'projects-new.field-name-error',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'projects-new.field-required',
        }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values) => {
      mutation.mutate({
        id: uuid(),
        name: values.name,
        status: ProjectStatus.ACTIVE,
      });
    },
  });

  return (
    <BaseLayout title={intl.formatMessage({ id: 'projects-new.title' })}>
      <Form onSubmit={formik.handleSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'projects-new.field-name-label',
          })}
          type="text"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.errors.name}
        />
        <Button
          type="submit"
          disabled={!(formik.isValid && formik.dirty) || mutation.isPending}
        >
          <FormattedMessage id="projects-new.submit-button" />
        </Button>
        <BackendError>{backendError}</BackendError>
      </Form>
    </BaseLayout>
  );
};

export default ProjectsNew;
