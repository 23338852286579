import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { colors, hexColorToRGB } from 'stylesheet';
import Title from 'components/Title';

import { Container } from './ScoreDistribution.style';
import { ProjectStats } from 'clients/admin/projects/entities/project-stats';
import { computeChartData } from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface Props {
  projectStats: ProjectStats[];
}

const ScoreDistribution: React.FC<Props> = ({ projectStats }) => {
  const intl = useIntl();

  const chartData = Object.values(computeChartData(projectStats));

  return (
    <Container>
      <>
        <Title>
          <FormattedMessage id="admin.chart-global-score-distribution.title" />
        </Title>
        <Bar
          options={{
            responsive: true,
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: Math.max(...chartData) + 5,
                beginAtZero: true,
              },
              x: {
                beginAtZero: true,
              },
            },
          }}
          data={{
            labels: Array.from(Array(101).keys()),
            datasets: [
              {
                label: intl.formatMessage({
                  id: 'admin.chart-global-score-distribution.legend',
                }),
                data: chartData,
                borderColor: `rgb(${hexColorToRGB(colors.pink)})`,
                backgroundColor: `rgba(${hexColorToRGB(colors.pink)}, 0.5)`,
              },
            ],
          }}
        />
      </>
    </Container>
  );
};

export default ScoreDistribution;
