import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { font, getSpacing, onSmallScreen, outline } from 'stylesheet';

export const SecondHeader = styled.div`
  padding: 0 ${getSpacing(5)};
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: ${getSpacing(6)};
  ${onSmallScreen(css`
    overflow-x: scroll;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`;

export const HeaderLink = styled(Link)<{ selected: boolean }>`
  width: ${getSpacing(20)};
  padding: 0 ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getSpacing(1)};
  ${font.size16}
  ${outline(-4)}

  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
    `};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(4)};
`;
