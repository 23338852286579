import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, font, getSpacing } from 'stylesheet';
import { ReactComponent as chevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as chevronUp } from 'assets/chevron-up.svg';

export const StyledTable = styled.table`
  width: fit-content;
`;

export const Tr = styled.tr<{
  gridTemplateColumnsCss: FlattenSimpleInterpolation;
}>`
  display: grid;
  grid-template-rows: auto;
  ${({ gridTemplateColumnsCss }) => gridTemplateColumnsCss}

  &:nth-child(even) {
    background-color: ${colors.gray_2};
  }
`;

export const Th = styled.th`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${colors.gray0};
`;

export const ThButton = styled.button`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${getSpacing(1)} ${getSpacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.purple};
  cursor: pointer;
  ${font.size16};
`;

export const Td = styled.td`
  padding: ${getSpacing(1)} ${getSpacing(2)};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${font.size14};
`;

export const ChevronDownIcon = styled(chevronDown)`
  height: ${getSpacing(1.5)};
  color: ${colors.purple};
`;

export const ChevronUpIcon = styled(chevronUp)`
  height: ${getSpacing(1.5)};
  color: ${colors.purple};
`;

export const Tbody = styled.tbody``;

export const Thead = styled.thead``;

export const ButtonLink = styled(Link)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
