import React from 'react';
import { ProjectVersion } from 'clients/projectVersions/type';

import {
  Container,
  CategoriesContainer,
  ScoreAndChartContainer,
} from './SummaryTab.style';
import CategorySummary from './components/CategorySummary';
import Score from './components/Score';
import Charts from './components/Charts/Charts';
import LastAchievements from './components/LastAchievements';
interface Props {
  projectVersion: ProjectVersion;
}

const SummaryTab: React.FC<Props> = ({ projectVersion }) => {
  return (
    <Container>
      <CategoriesContainer>
        {projectVersion.categories.map((category) => (
          <CategorySummary key={category.name} category={category} />
        ))}
      </CategoriesContainer>
      <ScoreAndChartContainer>
        <Score projectVersion={projectVersion} />
        <LastAchievements projectVersion={projectVersion} />
        <Charts
          categories={projectVersion.categories}
          score={projectVersion.score}
          scoreHistory={projectVersion.scoreHistory}
        />
      </ScoreAndChartContainer>
    </Container>
  );
};

export default SummaryTab;
