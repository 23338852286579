import React from 'react';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

import { colors, hexColorToRGB } from 'stylesheet';
import Title from 'components/Title';
import { ProjectVersion } from 'clients/projectVersions/type';

interface Props {
  categories: ProjectVersion['categories'];
}

export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  scales: {
    x: {
      suggestedMin: 0,
      suggestedMax: 25,
    },
  },
};

const ScoreByCategoryChart: React.FC<Props> = ({ categories }) => {
  const intl = useIntl();

  return (
    <>
      <Title>
        <FormattedMessage id="project.summary-tab.charts.by-category" />
      </Title>
      <Bar
        data={{
          labels: categories.map(({ name }) => name),
          datasets: [
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.target',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.purple)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.purple)}, 0.5)`,
              data: categories.map(
                ({ score: { totalInTargetOrDone } }) => totalInTargetOrDone,
              ),
            },
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.score',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.pink)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.pink)}, 0.5)`,
              data: categories.map(({ score: { score } }) => score),
            },
          ],
        }}
        options={options}
      />
    </>
  );
};

export default ScoreByCategoryChart;
