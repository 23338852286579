import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { createProject } from 'clients/projects/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { PATHS } from 'Router';
import { FixedTabsIds } from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/ProjectVersionPage';

export const useCreateProject = (
  setBackendError: Dispatch<SetStateAction<string | null>>,
) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: createProject,
    onSuccess: async (data) => {
      setBackendError(null);
      await queryClient.invalidateQueries({
        queryKey: [reactQueryKeys.projects],
      });
      navigate(
        generatePath(PATHS.PROJECT, {
          id: data,
          tabId: FixedTabsIds.SUMMARY,
        }),
      );
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        setBackendError(
          intl.formatMessage({
            id: 'projects-new.backend-error-already-exists',
          }),
        );
      } else {
        setBackendError(
          intl.formatMessage({ id: 'projects-new.backend-error-generic' }),
        );
      }
    },
  });
};
