import styled from 'styled-components';
import { colors, getSpacing } from 'stylesheet';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${getSpacing(4)};
`;

export const Image = styled.img`
  height: ${getSpacing(35)};
`;

export const Text = styled.p`
  color: ${colors.red};
`;
