import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import EditableCheckbox from 'components/EditableCheckbox';
import { toggleDone } from 'clients/achievements/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { Achievement } from 'clients/projectVersions/type';
import Checkbox from 'components/Checkbox';

interface Props {
  achievement: Achievement;
  projectVersionId: string;
  isEditable: boolean;
}

const DoneCheckbox: React.FC<Props> = ({
  achievement: { id, doneDate, doneDateLabel },
  projectVersionId,
  isEditable,
}) => {
  const queryClient = useQueryClient();
  const { locale } = useLocale();

  const toggleDoneMutation = useMutation({
    mutationFn: toggleDone,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: reactQueryKeys.projectVersion(projectVersionId, locale),
      });
    },
  });

  const isInitiallyChecked = doneDate !== null;

  return isEditable ? (
    <EditableCheckbox
      toggleChecked={() => toggleDoneMutation.mutate(id)}
      isInitiallyChecked={isInitiallyChecked}
      tooltipText={doneDateLabel}
    />
  ) : (
    <Checkbox isChecked={isInitiallyChecked} tooltipText={doneDateLabel} />
  );
};

export default DoneCheckbox;
