import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(4)};
`;

export const UpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(2)};
`;

export const VersionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(1)};
`;

export const Text = styled.p`
  ${font.size16}
`;

export const Info = styled.p`
  color: ${colors.gray2};
  font-style: italic;
  ${font.size14}
`;
