import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Category } from 'clients/projectVersions/type';
import Checkbox from 'components/Checkbox';
import Title from 'components/Title';

import {
  Container,
  FullGrid,
  ObjectiveContainer,
  ObjectiveHeaderCell,
  ObjectiveCell,
  Grid,
  HeaderCell,
  Cell,
  Score,
} from './CategorySummary.style';

interface Props {
  category: Category;
}

const CategorySummary: React.FC<Props> = ({ category }) => {
  return (
    <Container>
      <Title>{category.name}</Title>
      <FullGrid>
        <ObjectiveContainer>
          <ObjectiveHeaderCell>
            <FormattedMessage id="project.summary-tab.category-summary.objective" />
          </ObjectiveHeaderCell>
          <ObjectiveCell>{category.objectif}</ObjectiveCell>
        </ObjectiveContainer>
        <Grid>
          <HeaderCell>
            <FormattedMessage id="project.summary-tab.category-summary.subcategory" />
          </HeaderCell>
          <HeaderCell>
            <FormattedMessage id="project.summary-tab.category-summary.score" />
          </HeaderCell>
          <HeaderCell>
            <FormattedMessage id="project.summary-tab.category-summary.target" />
          </HeaderCell>
          <HeaderCell>
            <FormattedMessage id="project.summary-tab.category-summary.run" />
          </HeaderCell>
          {category.subcategories.map((subcategory) => (
            <Fragment key={subcategory.name}>
              <Cell>{subcategory.name}</Cell>
              <Cell>{subcategory.score.score}</Cell>
              <Cell>
                <Checkbox isChecked={subcategory.score.isTargetCompliant} />
              </Cell>
              <Cell>
                <Checkbox isChecked={subcategory.score.isRunCompliant} />
              </Cell>
            </Fragment>
          ))}
        </Grid>
      </FullGrid>
      <Score>
        <FormattedMessage
          id="project.summary-tab.category-summary.total-score"
          values={{
            categoryName: category.name,
            score: category.score.score,
            total: category.score.total,
          }}
        />
      </Score>
    </Container>
  );
};

export default CategorySummary;
