import { EnrichedProject, Project, ProjectRoles, ProjectStatus } from './type';
import { deleteRequest, get, patch, post } from 'clients/client';

export async function fetchProjects(): Promise<Project[]> {
  const response = await get<Project[]>(
    `${process.env.REACT_APP_BACKEND_URL}/projects`,
  );

  return response.data;
}

export async function fetchProject(id: string): Promise<EnrichedProject> {
  const response = await get<EnrichedProject>(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
  );

  return response.data;
}

type CreateProjectPayload = {
  id: string;
  name: string;
  status: ProjectStatus.ACTIVE;
};

export async function createProject(
  project: CreateProjectPayload,
): Promise<string> {
  await post<Project, CreateProjectPayload>(
    `${process.env.REACT_APP_BACKEND_URL}/projects`,
    project,
  );

  return project.id;
}

type MigrateProjectPayload = {
  projectId: string;
  fromProjectVersionId: string;
  toVersion: string;
};

export async function migrateProject(
  migrateProjectPayload: MigrateProjectPayload,
): Promise<void> {
  await post<
    Project,
    {
      fromProjectVersionId: string;
      toVersion: string;
    }
  >(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${migrateProjectPayload.projectId}/migrate`,
    {
      fromProjectVersionId: migrateProjectPayload.fromProjectVersionId,
      toVersion: migrateProjectPayload.toVersion,
    },
  );
}

export async function deleteProject(projectId: string): Promise<void> {
  await deleteRequest(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${projectId}`,
  );
}

export async function deleteUserFromProject({
  projectId,
  userId,
}: {
  projectId: string;
  userId: string;
}): Promise<string> {
  await deleteRequest(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${projectId}/users/${userId}`,
  );

  return userId;
}

export async function addUserToProject({
  projectId,
  userEmail,
  projectRole,
}: {
  projectId: string;
  userEmail: string;
  projectRole: ProjectRoles;
}): Promise<void> {
  await post<Project, { userEmail: string; userProjectRole: ProjectRoles }>(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${projectId}/users`,
    { userEmail, userProjectRole: projectRole },
  );
}

export async function updateProjectStatus({
  projectId,
  projectStatus,
}: {
  projectId: string;
  projectStatus: string;
}): Promise<void> {
  await patch<Project, { projectStatus: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${projectId}/status`,
    { projectStatus },
  );
}

export async function addExternalUser({
  userEmail,
}: {
  userEmail: string;
}): Promise<void> {
  await post<Project, { email: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/users/external`,
    { email: userEmail },
  );
}
