import styled from 'styled-components';
import { font, getSpacing } from 'stylesheet';

export const Achievement = styled.div`
  display: flex;
  ${font.size14};
  column-gap: ${getSpacing(2)};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;
