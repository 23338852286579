export const reactQueryKeys = {
  projects: 'projects',
  project: (id: string) => ['project', id],
  user: 'user',
  projectVersion: (id: string, locale: string) => [
    'projectVersions',
    id,
    locale,
  ],
  adminProjects: (locale: string) => ['adminProjects', locale],
  externalUser: 'externalUser',
  achievements: (locale: string) => ['achievements', locale],
};
