import { roundToDecimal } from 'pages/Admin/utils';

export function calculateAverage(numbers: number[]): number {
  if (numbers.length === 0) {
    return 0;
  }

  const sum = numbers.reduce((total, number) => total + number, 0);

  return roundToDecimal(sum / numbers.length);
}

export function calculateMedian(numbers: number[]): number {
  if (numbers.length === 0) {
    return 0;
  }

  const sortedArray = numbers.slice().sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedArray.length / 2);

  if (sortedArray.length % 2 === 1) {
    return sortedArray[middleIndex];
  } else {
    return roundToDecimal(
      (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2,
    );
  }
}

export function calculateStandardDeviation(numbers: number[]): number {
  const average = calculateAverage(numbers);

  const squaredDifferences = numbers.map((number) =>
    Math.pow(number - average, 2),
  );

  const averageOfSquaredDifferences = calculateAverage(squaredDifferences);

  return roundToDecimal(Math.sqrt(averageOfSquaredDifferences));
}

export function min(numbers: number[]): number {
  return Math.min(...numbers);
}

export function max(numbers: number[]): number {
  return Math.max(...numbers);
}
