import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { callGetAccessTokenSilently } from 'states/auth0';
import { getNavigationFunction } from 'states/router';
import { PATHS } from 'Router';

const config = async () => {
  const token = await callGetAccessTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const get = async <ResponseT>(
  url: string,
  extraConfig?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.get(url, { ...(await config()), ...extraConfig }).catch(onError);
};

export const post = async <ResponseT, DataT = void>(
  url: string,
  data?: DataT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.post(url, data, await config()).catch(onError);
};

export const put = async <ResponseT, DataT = void>(
  url: string,
  data?: DataT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.put(url, data, await config()).catch(onError);
};

export const patch = async <ResponseT, DataT = void>(
  url: string,
  data?: DataT,
): Promise<AxiosResponse<ResponseT>> => {
  return axios.patch(url, data, await config()).catch(onError);
};

export const deleteRequest = async (
  url: string,
): Promise<AxiosResponse<void>> => {
  return axios.delete(url, await config()).catch(onError);
};

const onError = (e: AxiosError) => {
  handleAuthorizationError(e);

  throw e;
};

const handleAuthorizationError = (error: AxiosError) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    getNavigationFunction()(PATHS.AUTHENTICATION_ERROR);
  }
};
