import { useMutation, useQueryClient } from '@tanstack/react-query';

import { comment } from 'clients/achievements/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';

export const useComment = (projectVersionId: string) => {
  const queryClient = useQueryClient();
  const { locale } = useLocale();

  return useMutation({
    mutationFn: comment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reactQueryKeys.projectVersion(projectVersionId, locale),
      });
    },
  });
};
