import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EnrichedProject, ProjectStatus } from 'clients/projects/type';
import Title from 'components/Title';
import Picker from 'components/Picker';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { updateProjectStatus } from 'clients/projects/client';

import { Container, InnerContainer, Text } from './InformationSettings.style';

interface Props {
  project: EnrichedProject;
}

const InformationSettings: React.FC<Props> = ({ project }) => {
  const queryClient = useQueryClient();

  const statusMutation = useMutation({
    mutationFn: updateProjectStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: reactQueryKeys.project(project.id),
      });
    },
  });

  return (
    <Container>
      <Title>
        <FormattedMessage id="project.settings-tab.information-settings.title" />
      </Title>
      <InnerContainer>
        <Text>
          <FormattedMessage id="project.settings-tab.information-settings.project-status" />
        </Text>
        <Picker
          pickedValue={project.status}
          values={Object.values(ProjectStatus)}
          onValuePicked={(status) => {
            statusMutation.mutate({
              projectId: project.id,
              projectStatus: status,
            });
          }}
        />
      </InnerContainer>
    </Container>
  );
};

export default InformationSettings;
