import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { fetchProjectStats } from 'clients/admin/projects/client';

import { Container } from './ChartTab.style';
import ScoreDistribution from './components/ScoreDistribution';

const ChartTab: React.FC = () => {
  const { locale } = useLocale();
  const { data: projectStats } = useQuery({
    queryKey: reactQueryKeys.adminProjects(locale),
    queryFn: () => fetchProjectStats(locale),
  });

  return (
    <Container>
      {projectStats && (
        <ScoreDistribution projectStats={projectStats}></ScoreDistribution>
      )}
    </Container>
  );
};

export default ChartTab;
