import React, { ReactElement } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { addNavigateFunction } from 'states/router';

import Projects from 'pages/Projects';
import ProjectsNew from 'pages/ProjectsNew';
import ProjectPage from 'pages/ProjectPage';
import AuthenticationError from 'pages/Errors/AuthenticationError';
import AdminPage from 'pages/Admin';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { fetchUser } from 'clients/users/client';

export const PATHS = {
  AUTHENTICATION_ERROR: '/authentication-error',
  HOME: '/',
  PROJECT: '/projects/:id/:tabId',
  PROJECTS: '/projects',
  PROJECTS_NEW: '/projects/new',
  ADMIN: '/admin/:tabId',
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = PATHS.HOME,
  children,
}: {
  isAllowed: boolean;
  redirectPath: string;
  children: ReactElement;
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export type projectPathParams = {
  id: string;
  tabId: string;
};

const Router: React.FC = () => {
  const navigate = useNavigate();
  addNavigateFunction(navigate);

  const userQuery = useQuery({
    queryKey: [reactQueryKeys.user],
    queryFn: fetchUser,
  });

  return (
    <Routes>
      <Route
        path={PATHS.AUTHENTICATION_ERROR}
        element={<AuthenticationError />}
      />
      <Route
        path={PATHS.HOME}
        element={<Navigate replace to={PATHS.PROJECTS} />}
      />
      <Route path={PATHS.PROJECT} element={<ProjectPage />} />
      <Route path={PATHS.PROJECTS} element={<Projects />} />
      <Route path={PATHS.PROJECTS_NEW} element={<ProjectsNew />} />
      <Route
        path={PATHS.ADMIN}
        element={
          <ProtectedRoute
            redirectPath={PATHS.HOME}
            isAllowed={!!userQuery.data?.isAtLeastPadokien()}
          >
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate replace to={PATHS.PROJECTS} />} />
    </Routes>
  );
};

export default Router;
