import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import {
  formatDateToLocaleString,
  toValueLabelFormat,
  ValueLabel,
} from 'clients/utils';
import { ProjectStatsBackend } from '../type';

type ScoreByCategory = Record<string, ValueLabel>;

export class ProjectStats {
  id: string;
  name: string;
  status: string;
  lastWonPoint: Date | null;
  score: {
    score: number;
    total: number;
    runScore: number;
    runTotal: number;
    categories: Record<string, { score: number; total: number }>;
  };

  constructor(projectStats: ProjectStatsBackend) {
    this.id = projectStats.id;
    this.name = projectStats.name;
    this.status = projectStats.status;
    this.lastWonPoint = projectStats.lastWonPoint
      ? new Date(projectStats.lastWonPoint)
      : null;
    this.score = projectStats.score;
  }

  getComplianceRunInPercent(): number {
    return parseFloat(
      ((this.score.runScore * 100) / this.score.runTotal).toFixed(1),
    );
  }

  toTable(): Record<string, ValueLabel> {
    const complianceRun = this.getComplianceRunInPercent();
    const score = this.score.score;
    const lastWonPoint = this.lastWonPoint;
    const categories = this.score.categories;
    const scoreByCategory = Object.entries(categories).reduce<ScoreByCategory>(
      (acc, [category, { score, total }]) => {
        acc[category] = toValueLabelFormat(score, `${score}/${total}`);
        return acc;
      },
      {},
    );

    return {
      id: toValueLabelFormat(this.id),
      name: toValueLabelFormat(this.name),
      score: toValueLabelFormat(score, `${score}/${this.score.total}`),
      run: toValueLabelFormat(complianceRun, `${complianceRun}%`),
      lastWonPoint: toValueLabelFormat(
        lastWonPoint,
        lastWonPoint
          ? formatDateToLocaleString(new Date(lastWonPoint), Locales.FR)
          : '',
      ),
      ...scoreByCategory,
    };
  }
}
