import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProjectStatus } from 'clients/projects/type';

import { PATHS } from 'Router';
import BaseLayout from 'layout/BaseLayout';
import { fetchProjects } from 'clients/projects/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { FixedTabsIds } from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/ProjectVersionPage';
import { ButtonLink } from 'components/Button/Button';
import Title from 'components/Title';

import {
  Container,
  List,
  ListItem,
  StyledLink,
  ButtonContainer,
  Text,
  InnerContainer,
} from './Projects.style';
import { fetchUser } from 'clients/users/client';
import ExternalUsersNew from './components/ExternalUsersNew';

const Projects: React.FC = () => {
  const projectsQuery = useQuery({
    queryKey: [reactQueryKeys.projects],
    queryFn: fetchProjects,
  });
  const userQuery = useQuery({
    queryKey: [reactQueryKeys.user],
    queryFn: fetchUser,
  });
  const intl = useIntl();

  return (
    <BaseLayout title={intl.formatMessage({ id: 'projects.title' })}>
      <Container>
        {!projectsQuery.isLoading &&
          (projectsQuery.isSuccess && projectsQuery.data.length !== 0 ? (
            <InnerContainer>
              {projectsQuery.data.filter(
                (item) => item.status === ProjectStatus.ACTIVE,
              ).length !== 0 && (
                <InnerContainer>
                  <Title>
                    <FormattedMessage id="projects.active-projects" />
                  </Title>
                  <List>
                    {projectsQuery.data
                      .filter((item) => item.status === ProjectStatus.ACTIVE)
                      .map((project) => (
                        <ListItem key={project.id}>
                          <StyledLink
                            to={generatePath(PATHS.PROJECT, {
                              id: project.id,
                              tabId: FixedTabsIds.SUMMARY,
                            })}
                          >
                            {project.name}
                          </StyledLink>
                        </ListItem>
                      ))}
                  </List>
                </InnerContainer>
              )}
              {projectsQuery.data.filter(
                (item) => item.status === ProjectStatus.FINISHED,
              ).length !== 0 && (
                <InnerContainer>
                  <Title>
                    <FormattedMessage id="projects.finished-projects" />
                  </Title>
                  <List>
                    {projectsQuery.data
                      .filter((item) => item.status === ProjectStatus.FINISHED)
                      .map((project) => (
                        <ListItem key={project.id}>
                          <StyledLink
                            to={generatePath(PATHS.PROJECT, {
                              id: project.id,
                              tabId: FixedTabsIds.SUMMARY,
                            })}
                          >
                            {project.name}
                          </StyledLink>
                        </ListItem>
                      ))}
                  </List>
                </InnerContainer>
              )}
            </InnerContainer>
          ) : (
            <Text>
              <FormattedMessage id="projects.no-project" />
            </Text>
          ))}
        {userQuery.isSuccess && userQuery.data.isAtLeastPadokien() && (
          <InnerContainer>
            <ButtonContainer>
              <ButtonLink to={PATHS.PROJECTS_NEW}>
                <FormattedMessage id="projects.new-project-button" />
              </ButtonLink>
            </ButtonContainer>
            <ExternalUsersNew />
          </InnerContainer>
        )}
      </Container>
    </BaseLayout>
  );
};

export default Projects;
