import React, { ChangeEvent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { ProjectStats } from 'clients/admin/projects/entities/project-stats';

import { ProjectStatus } from 'clients/projects/type';
import { generatePath } from 'react-router-dom';
import { PATHS } from 'Router';
import { FixedTabsIds } from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/ProjectVersionPage';

import {
  Container,
  SearchContainer,
  InputSearchContainer,
  InputSearch,
  FiltersContainer,
  DataCount,
  gridTemplateColumnsCss,
  EyeIcon,
} from './StatsByProject.style';
import ScoreFilter from './components/ScoreFilter';
import Title from 'components/Title';
import TableComponent, { Data } from 'components/Table';
import Filter from 'components/Filter';
import Button from 'components/Button';

import { getTable } from './utils';

interface Props {
  projectStats: ProjectStats[];
}

const StatsByProject: React.FC<Props> = ({ projectStats }) => {
  const { locale } = useLocale();
  const intl = useIntl();

  const table = getTable(projectStats, locale);

  const [displayedData, setDisplayedData] = useState(table.data);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [scoreFilterOperator, setScoreFilterOperator] = useState<string>('');
  const [scoreFilterValue, setScoreFilterValue] = useState<string>('');
  const [searchBarFilter, setSearchBarFilter] = useState('');
  const count = displayedData.length;

  const handleSearchBar = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchBarFilter(e.target.value);
  };

  const handleFilter = () => {
    const table = getTable(projectStats, locale);
    const filtered = table.data
      .filter((item) =>
        table.headers.some((header) =>
          String(item[header.key].label)
            .toLowerCase()
            .includes(searchBarFilter.toLowerCase()),
        ),
      )
      .filter((item) => {
        if (statusFilter === '') {
          return true;
        }
        if (statusFilter === 'all') {
          return true;
        }
        return item.status.value === statusFilter;
      })
      .filter((item) => {
        if (scoreFilterOperator === '') {
          return true;
        }
        if (scoreFilterValue === '') {
          return true;
        }
        switch (scoreFilterOperator) {
          case '<':
            return (item.score.value as number) < Number(scoreFilterValue);
          case '<=':
            return (item.score.value as number) <= Number(scoreFilterValue);
          case '>':
            return (item.score.value as number) > Number(scoreFilterValue);
          case '>=':
            return (item.score.value as number) >= Number(scoreFilterValue);
          case '=':
            return item.score.value === Number(scoreFilterValue);
          default:
            return true;
        }
      });
    setDisplayedData(filtered);
  };

  useEffect(() => {
    handleFilter();
  }, [
    projectStats,
    searchBarFilter,
    statusFilter,
    scoreFilterOperator,
    scoreFilterValue,
  ]);

  const goTo = (item: Data) => {
    return generatePath(PATHS.PROJECT, {
      id: item.id.value,
      tabId: FixedTabsIds.SUMMARY,
    });
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id={`admin.table-stats-by-project.title`} />
      </Title>
      <SearchContainer>
        <DataCount>
          {`${count} ${intl.formatMessage({
            id: `admin.table-stats-by-project.count`,
          })}${count > 1 ? 's' : ''}`}
        </DataCount>
        <InputSearchContainer>
          <InputSearch
            type="text"
            placeholder={intl.formatMessage({
              id: 'admin.table-component.search',
            })}
            value={searchBarFilter}
            onChange={handleSearchBar}
          />
        </InputSearchContainer>
      </SearchContainer>
      <FiltersContainer>
        <Filter
          placeholder={intl.formatMessage({
            id: 'admin.table-component.status-filter',
          })}
          pickedValue={statusFilter}
          values={['all', ...Object.values(ProjectStatus)]}
          onValuePicked={setStatusFilter}
        />
        <ScoreFilter
          scoreOperator={scoreFilterOperator}
          onScoreOperatorPicked={setScoreFilterOperator}
          scoreValue={scoreFilterValue}
          setScoreValue={setScoreFilterValue}
        />
        <Button
          onClick={() => {
            setStatusFilter('');
            setScoreFilterOperator('');
            setScoreFilterValue('');
          }}
        >
          <FormattedMessage id="admin.table-component.clear-filters" />
        </Button>
      </FiltersContainer>
      <TableComponent
        headers={table.headers}
        data={displayedData}
        gridTemplateColumnsCss={gridTemplateColumnsCss}
        iconLink={{
          goTo: (item: Data) => goTo(item),
          iconElement: <EyeIcon />,
        }}
      />
    </Container>
  );
};

export default StatsByProject;
