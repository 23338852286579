import styled, { css } from 'styled-components';

import { colors, getSpacing, outline } from 'stylesheet';
import { ReactComponent as eye } from 'assets/eye.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(1)};
`;

export const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${getSpacing(1)};
  grid-row-gap: 0px;
`;

export const InputSearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InputSearch = styled.input`
  width: 400px;
  padding: ${getSpacing(1)} ${getSpacing(1.5)};
  border-radius: 4px;
  border: 1px solid ${colors.gray0};
  margin-bottom: ${getSpacing(1)};
  align-items: center;
  &:focus {
    ${outline()};
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
  align-items: center;
`;

export const DataCount = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const gridTemplateColumnsCss = css`
  grid-template-columns:
    ${getSpacing(30)}
    repeat(6, ${getSpacing(15)})
    ${getSpacing(20)}
    ${getSpacing(15)}
    ${getSpacing(10)};
`;

export const EyeIcon = styled(eye)`
  color: ${colors.purple};
  height: ${getSpacing(2.5)};
`;
