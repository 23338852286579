import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, font, getSpacing, onSmallScreen, outline } from 'stylesheet';
import { ReactComponent as TriangleExclamation } from 'assets/triangle-exclamation.svg';
import { ReactComponent as InformationCircle } from 'assets/information-circle.svg';

export const SecondHeader = styled.div`
  padding: 0 ${getSpacing(5)};
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: ${getSpacing(6)};
  ${onSmallScreen(css`
    overflow-x: scroll;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`;

export const HeaderLink = styled(Link)<{ selected: boolean }>`
  width: ${getSpacing(12)};
  padding: 0 ${getSpacing(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getSpacing(1)};
  ${font.size16}
  ${outline(-4)}

  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
    `};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(4)};
`;

export const InformationList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${getSpacing(1)};
`;

export const InformationIcon = styled(InformationCircle)`
  height: ${getSpacing(2.5)};
  color: ${colors.gray2};
`;

export const Information = styled.div`
  color: ${colors.gray2};
  padding: ${getSpacing(0.5)} ${getSpacing(1)};
  border-radius: ${getSpacing(1)};
  display: flex;
  align-items: center;
  column-gap: ${getSpacing(1)};
  ${font.size14}
`;

export const WarningIcon = styled(TriangleExclamation)`
  width: ${getSpacing(2.5)};
  color: ${colors.red};
`;

export const Warning = styled(Information)`
  background-color: ${colors.red_2};
  color: ${colors.red};
  border: 1px solid ${colors.red};
  padding: ${getSpacing(0.5)} ${getSpacing(1)};
  border-radius: ${getSpacing(1)};
  display: flex;
  align-items: center;
  column-gap: ${getSpacing(1)};
  ${font.size14}
`;
