import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import { get } from 'clients/client';
import { ProjectStatsBackend } from './type';
import { ProjectStats } from './entities/project-stats';

export async function fetchProjectStats(
  locale: Locales,
): Promise<ProjectStats[]> {
  const response = await get<ProjectStatsBackend[]>(
    `${process.env.REACT_APP_BACKEND_URL}/admin/project-stats`,
    { params: { locale } },
  );

  return response.data.map(
    (projectStatsBackend) => new ProjectStats(projectStatsBackend),
  );
}
