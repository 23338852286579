import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import TableComponent from 'components/Table';
import { ProjectStats } from 'clients/admin/projects/entities/project-stats';
import { ValueLabel } from 'clients/utils';
import { mapDataToTable } from './mapDataToTable';
import Title from 'components/Title';
import { Container, gridTemplateColumnsCss } from './StatsForAllProjects.style';

interface Props {
  projectStats: ProjectStats[];
}

function getTranslatedHeaders(intl: IntlShape) {
  return ['score', 'average', 'min', 'max', 'standardDeviation', 'median'].map(
    (header) => ({
      key: header,
      label: intl.formatMessage({
        id: `admin.table-stats-for-all-projects.${header}`,
      }),
    }),
  );
}

function getTable(
  projectStats: ProjectStats[],
  intl: IntlShape,
): {
  headers: { key: string; label: string }[];
  data: Record<string, ValueLabel>[];
} {
  return {
    headers: getTranslatedHeaders(intl),
    data: mapDataToTable(projectStats),
  };
}

const StatsForAllProjects: React.FC<Props> = ({ projectStats }) => {
  const intl = useIntl();
  const table = getTable(projectStats, intl);

  return (
    <Container>
      <Title>
        <FormattedMessage id={`admin.table-stats-for-all-projects.title`} />
      </Title>
      <TableComponent
        headers={table.headers}
        data={table.data}
        gridTemplateColumnsCss={gridTemplateColumnsCss}
      />
    </Container>
  );
};

export default StatsForAllProjects;
