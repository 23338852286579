import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { fetchLastUpdatedAchievements } from 'clients/achievements/client';

import { ProjectVersion } from 'clients/projectVersions/type';
import Title from 'components/Title';
import { Achievement, Container } from './LastAchievements.style';
import Checkbox from 'components/Checkbox';

interface Props {
  projectVersion: ProjectVersion;
}

const LastAchievements: React.FC<Props> = ({ projectVersion }) => {
  const { locale } = useLocale();
  const { data: achievements } = useQuery({
    queryKey: [reactQueryKeys.achievements(locale), projectVersion.id],
    queryFn: () => fetchLastUpdatedAchievements(locale, projectVersion.id),
  });
  return (
    <Container>
      <Title>
        <FormattedMessage id="project.summary-tab.achievement.title" />
      </Title>

      {achievements?.length ? (
        <>
          {achievements.map(
            ({ id, dateLabel, description, checked }, index) => (
              <Achievement key={`${id}-${index}`}>
                <Checkbox isChecked={checked} />
                <span>{dateLabel}</span>
                <span>
                  {description?.length > 78
                    ? `${description.substring(0, 78)}...`
                    : description || ''}
                </span>
              </Achievement>
            ),
          )}
        </>
      ) : (
        <div>
          <FormattedMessage id="project.summary-tab.achievement.noAchievements" />
        </div>
      )}
    </Container>
  );
};

export default LastAchievements;
