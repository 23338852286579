import styled from 'styled-components';
import { font, getSpacing, outline } from 'stylesheet';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  height: 100%;
  padding-top: ${getSpacing(1)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: ${getSpacing(3)};
  justify-content: space-between;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(4)};
  flex: 1;
`;

export const List = styled.ul`
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const ListItem = styled.li``;

export const StyledLink = styled(Link)`
  padding: ${getSpacing(0.5)} ${getSpacing(1)};
  border-radius: ${getSpacing(0.5)};
  ${font.size16};
  ${outline(0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Text = styled.p`
  ${font.size16}
`;
