import React from 'react';

import { Category } from 'clients/projectVersions/type';
import { EnrichedProject } from 'clients/projects/type';

import { Container } from './CategoryTab.style';
import SubcategoryDetails from './components/SubcategoryDetails/SubcategoryDetails';

interface Props {
  category: Category;
  projectVersionId: string;
  project: EnrichedProject;
}

const CategoryTab: React.FC<Props> = ({
  category,
  projectVersionId,
  project,
}) => {
  return (
    <Container>
      {category.subcategories.map((subcategory) => (
        <SubcategoryDetails
          key={subcategory.id}
          subcategory={subcategory}
          projectVersionId={projectVersionId}
          project={project}
        />
      ))}
    </Container>
  );
};

export default CategoryTab;
