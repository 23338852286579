import styled from 'styled-components';
import { colors, getSpacing, outline } from 'stylesheet';
import Button from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${getSpacing(2)};
`;

export const DeleteButton = styled(Button)`
  color: ${colors.red};
  border-color: ${colors.red};
  ${outline(4, colors.red_1)}
`;
