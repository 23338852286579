import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import {
  formatDateToLocaleString,
  toValueLabelFormat,
  ValueLabel,
} from 'clients/utils';
import { ProjectStats } from 'clients/admin/projects/entities/project-stats';

export function getTable(
  projectStats: ProjectStats[],
  locale: Locales,
): {
  headers: { key: string; label: string }[];
  data: Record<string, ValueLabel>[];
} {
  return {
    headers: getTranslatedHeaders(locale),
    data: getData(projectStats, locale),
  };
}

const headers = [
  { key: 'name', label: { fr: 'Projet', en: 'Project' } },
  { key: 'status', label: { fr: 'Statut', en: 'Status' } },
  { key: 'score', label: { fr: 'Score', en: 'Score' } },
  {
    key: 'resilient',
    label: { fr: 'Resilient', en: 'Resilient' },
  },
  {
    key: 'operable',
    label: { fr: 'Operable', en: 'Operable' },
  },
  { key: 'secure', label: { fr: 'Secure', en: 'Secure' } },
  {
    key: 'empowering',
    label: { fr: 'Empow.', en: 'Empow.' },
  },
  {
    key: 'lastWonPoint',
    label: { fr: 'Dernier point gagné', en: 'Last won point' },
  },
  {
    key: 'run',
    label: { fr: 'Run', en: 'Run' },
  },
];

export function getTranslatedHeaders(locale: Locales) {
  return headers.map((header) => ({
    key: header.key,
    label: header.label[locale],
  }));
}

function getData(
  projectStats: ProjectStats[],
  locale: Locales,
): Record<string, ValueLabel>[] {
  return projectStats.map((projectStats) => toData(projectStats, locale));
}

function toData(
  projectStats: ProjectStats,
  locale: Locales,
): Record<string, ValueLabel> {
  const complianceRun = projectStats.getComplianceRunInPercent();
  const score = projectStats.score.score;
  const lastWonPoint = projectStats.lastWonPoint;
  const categories = projectStats.score.categories;
  const scoreByCategory = Object.entries(categories).reduce<
    Record<string, ValueLabel>
  >((acc, [category, { score, total }]) => {
    acc[category] = toValueLabelFormat(score, `${score}/${total}`);
    return acc;
  }, {});

  return {
    id: toValueLabelFormat(projectStats.id),
    name: toValueLabelFormat(projectStats.name),
    status: toValueLabelFormat(projectStats.status),
    score: toValueLabelFormat(score, `${score}/${projectStats.score.total}`),
    run: toValueLabelFormat(complianceRun, `${complianceRun}%`),
    lastWonPoint: toValueLabelFormat(
      lastWonPoint,
      lastWonPoint
        ? formatDateToLocaleString(new Date(lastWonPoint), locale)
        : '',
    ),
    ...scoreByCategory,
  };
}
