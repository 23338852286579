import React, { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { addGetAccessTokenSilently } from 'states/auth0';
import AuthenticationError from 'pages/Errors/AuthenticationError';

import Login from './components/Login';

interface Props {
  children: ReactNode;
}

const Auth0Handler: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } =
    useAuth0();

  addGetAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <AuthenticationError error={error.message} />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }
  return <>{children}</>;
};

export default Auth0Handler;
