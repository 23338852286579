import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${getSpacing(36)} repeat(4, ${getSpacing(8)}) ${getSpacing(
      48,
    )} auto;
`;

export const TextHeaderCell = styled.div`
  padding: ${getSpacing(1)} ${getSpacing(2)};
  border-bottom: 1px solid ${colors.gray0};
  display: flex;
  justify-content: flex-start;
  color: ${colors.purple};
  ${font.size16};
`;

export const HeaderCell = styled.div`
  padding: ${getSpacing(1)};
  border-bottom: 1px solid ${colors.gray0};
  display: flex;
  justify-content: center;
  color: ${colors.purple};
  ${font.size16};
`;

export const TextCell = styled.div`
  border-bottom: 1px solid ${colors.gray_1};
  display: flex;
  align-items: start;
  ${font.size14};
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  text-align: left;
`;

export const NumberCell = styled.div`
  border-bottom: 1px solid ${colors.gray_1};
  display: flex;
  align-items: center;
  ${font.size14};
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  justify-content: center;
`;
