import styled, { css } from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${getSpacing(0.5)};
`;

export const Label = styled.label<{ hasError: boolean }>`
  padding-left: ${getSpacing(0.5)};
  ${font.size16}

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red};
    `}
`;

export const Input = styled.input<{ hasError: boolean }>`
  display: block;
  border: 1px solid ${colors.gray0};
  border-radius: ${getSpacing(0.5)};
  height: ${getSpacing(4)};
  background-color: ${colors.gray_2};
  padding: 0 ${getSpacing(1)};
  ${font.size16};
  ${outline()};

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red};
      border-color: ${colors.red};
    `}
`;

export const Error = styled.p`
  color: ${colors.red};
  ${font.size14}
`;
