import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import { ProjectVersion, ProjectVersionBackend } from './type';
import { get } from 'clients/client';
import { formatDateToLocaleString } from 'clients/utils';

export async function fetchProjectVersion(
  projectVersionId: string,
  locale: Locales,
): Promise<ProjectVersion> {
  const response = await get<ProjectVersionBackend>(
    `${process.env.REACT_APP_BACKEND_URL}/project-versions/${projectVersionId}`,
    { params: { locale } },
  );

  const projectVersionBackend = response.data;

  return {
    id: projectVersionBackend.id,
    version: projectVersionBackend.version,
    categories: projectVersionBackend.categories.map((category) => ({
      id: category.id,
      key: category.key,
      name: category.name,
      objectif: category.objectif,
      subcategories: category.subcategories.map((subcategory) => ({
        id: subcategory.id,
        name: subcategory.name,
        criterias: subcategory.criterias.map((criteria) => ({
          id: criteria.id,
          description: criteria.description,
          weight: criteria.weight,
          isRunMandatory: criteria.isRunMandatory,
          explanation: criteria.explanation,
          achievement: {
            id: criteria.achievement.id,
            criteriaKey: criteria.achievement.criteriaKey,
            projectVersionId: criteria.achievement.projectVersionId,
            doneDate: criteria.achievement.doneDate
              ? new Date(criteria.achievement.doneDate)
              : null,
            doneDateLabel: criteria.achievement.doneDate
              ? formatDateToLocaleString(
                  new Date(criteria.achievement.doneDate),
                  locale,
                )
              : '',
            target: criteria.achievement.target,
            comment: criteria.achievement.comment,
          },
        })),
        score: {
          score: subcategory.score.score,
          isTargetCompliant: subcategory.score.isTargetCompliant,
          isRunCompliant: subcategory.score.isRunCompliant,
        },
      })),
      score: {
        score: category.score.score,
        total: category.score.total,
        totalInTargetOrDone: category.score.totalInTargetOrDone,
      },
    })),
    score: {
      score: projectVersionBackend.score.score,
      total: projectVersionBackend.score.total,
      targetScore: projectVersionBackend.score.targetScore,
      targetTotal: projectVersionBackend.score.targetTotal,
      runScore: projectVersionBackend.score.runScore,
      runTotal: projectVersionBackend.score.runTotal,
      totalInTargetOrDone: projectVersionBackend.score.totalInTargetOrDone,
      runTotalInTargetOrDone:
        projectVersionBackend.score.runTotalInTargetOrDone,
    },
    scoreHistory: projectVersionBackend.scoreHistory,
  };
}
