import { ProjectVersionMetadata } from 'clients/projects/type';
import Picker from 'components/Picker/Picker';
import React from 'react';

interface Props {
  currentProjectVersion: ProjectVersionMetadata;
  allProjectVersions: ProjectVersionMetadata[];
  onVersionPicked: (projectVersion: ProjectVersionMetadata) => void;
}
const VersionPicker: React.FC<Props> = ({
  currentProjectVersion,
  allProjectVersions,
  onVersionPicked,
}) => {
  const getProjectVersion = (version: string) =>
    allProjectVersions.find((pj) => pj.version === version);

  const projectVersionsArr = allProjectVersions.map((pv) => pv.version);

  return (
    <Picker
      pickedValue={currentProjectVersion.version}
      onValuePicked={(version) => {
        const projectVersion = getProjectVersion(version);
        if (projectVersion === undefined) return;
        onVersionPicked(projectVersion);
      }}
      values={projectVersionsArr}
    />
  );
};

export default VersionPicker;
