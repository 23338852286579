import React from 'react';

import { ProjectVersion } from 'clients/projectVersions/type';

import ComplianceRunChart from './components/ComplianceRunChart';
import ScoreByCategory from './components/ScoreByCategoryChart';
import ScoreEvolutionChart from './components/ScoreEvolutionChart/ScoreEvolutionChart';
import { Container } from './Charts.style';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

interface Props {
  categories: ProjectVersion['categories'];
  score: ProjectVersion['score'];
  scoreHistory: ProjectVersion['scoreHistory'];
}

const Charts: React.FC<Props> = ({
  categories,
  score: { runScore, runTotal, totalInTargetOrDone, runTotalInTargetOrDone },
  scoreHistory,
}) => {
  return (
    <Container>
      <ScoreEvolutionChart
        totalInTargetOrDone={totalInTargetOrDone}
        scoreHistory={scoreHistory}
      />
      <ScoreByCategory categories={categories} />
      <ComplianceRunChart
        runScore={runScore}
        runTotal={runTotal}
        runTotalInTargetOrDone={runTotalInTargetOrDone}
      />
    </Container>
  );
};

export default Charts;
