import styled from 'styled-components';
import { getSpacing, outline } from 'stylesheet';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: ${getSpacing(0.5)};
  ${outline()};
`;
