import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'Router';

import { EnrichedProject } from 'clients/projects/type';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { reactQueryKeys } from 'clients/reactQueryConfig';
import { deleteUserFromProject } from 'clients/projects/client';
import { fetchUser } from 'clients/users/client';
import Title from 'components/Title';

import {
  Container,
  UserList,
  Grid,
  HeaderCell,
  Cell,
  TrashButton,
  TrashIcon,
} from './UsersSettings.style';
import UsersNew from './components/UsersNew';

interface Props {
  project: EnrichedProject;
}

const UsersSettings: React.FC<Props> = ({ project }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useQuery({
    queryKey: [reactQueryKeys.user],
    queryFn: fetchUser,
  });

  const deleteUserFromProjectMutation = useMutation({
    mutationFn: deleteUserFromProject,
    onSuccess: async (deletedUserId) => {
      if (user.isSuccess && user.data.id === deletedUserId) {
        navigate(PATHS.PROJECTS);
      } else {
        await queryClient.invalidateQueries({
          queryKey: reactQueryKeys.project(project.id),
        });
      }
    },
  });

  return (
    <Container>
      <UserList>
        <Title>
          <FormattedMessage id="project.settings-tab.users-settings.users" />
        </Title>
        <Grid>
          <HeaderCell>
            <FormattedMessage id="project.settings-tab.users-settings.email" />
          </HeaderCell>
          <HeaderCell>
            <FormattedMessage id="project.settings-tab.users-settings.role" />
          </HeaderCell>
          <HeaderCell>
            <FormattedMessage id="project.settings-tab.users-settings.actions" />
          </HeaderCell>
          {project.userProjects.map((userProject) => (
            <Fragment key={userProject.userId}>
              <Cell>{userProject.userEmail}</Cell>
              <Cell>{userProject.projectRole}</Cell>
              <Cell>
                {!userProject.isLastAdminOfProject && (
                  <TrashButton
                    onClick={() => {
                      deleteUserFromProjectMutation.mutate({
                        projectId: project.id,
                        userId: userProject.userId,
                      });
                    }}
                  >
                    <TrashIcon />
                  </TrashButton>
                )}
              </Cell>
            </Fragment>
          ))}
        </Grid>
      </UserList>
      <UsersNew project={project} />
    </Container>
  );
};

export default UsersSettings;
