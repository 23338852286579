import React from 'react';
import {
  Root,
  Value,
  Icon,
  Portal,
  Group,
  ItemText,
} from '@radix-ui/react-select';

import {
  ChevronDownIcon,
  Container,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectViewport,
} from './Filter.style';

interface Props {
  placeholder: string;
  pickedValue: string | undefined;
  values: string[];
  onValuePicked: (value: string) => void;
}

const Filter = ({ placeholder, pickedValue, values, onValuePicked }: Props) => {
  return (
    <Container>
      <Root value={pickedValue} onValueChange={onValuePicked}>
        <SelectTrigger className="SelectTrigger">
          <Value placeholder={placeholder} />
          <Icon>
            <ChevronDownIcon />
          </Icon>
        </SelectTrigger>
        <Portal>
          <SelectContent position="popper">
            <SelectViewport>
              <Group>
                {values.map((val) => (
                  <SelectItem key={val} value={val}>
                    <ItemText>{val}</ItemText>
                  </SelectItem>
                ))}
              </Group>
            </SelectViewport>
          </SelectContent>
        </Portal>
      </Root>
    </Container>
  );
};

export default Filter;
