import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import TextField from 'components/TextField';
import Button from 'components/Button';
import Title from 'components/Title';
import { ProjectRoles } from 'clients/projects/type';
import { addExternalUser } from 'clients/projects/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';

import { BackendMessage, Container, Form } from './ExternalUsersNew.style';

const ExternalUserContainer: React.FC = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [backendError, setBackendError] = useState<string | null>(null);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: addExternalUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [reactQueryKeys.externalUser],
      });
      formik.resetForm();
      setBackendSuccess(
        intl.formatMessage({
          id: 'projects.add-external-user.backend-success',
        }),
      );
    },
    onError: async (error: AxiosError) => {
      const errorKey = (() => {
        switch (error.response?.status) {
          case 400:
            return 'projects.add-external-user.backend-error-400';
          case 409:
            return 'projects.add-external-user.backend-error-409';
          default:
            return 'projects.add-external-user.backend-error';
        }
      })();
      setBackendError(intl.formatMessage({ id: errorKey }));
    },
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: 'projects.add-external-user.field-email-error',
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      projectRole: ProjectRoles.EDITOR,
    },
    validationSchema,
    onSubmit: (values) => {
      setBackendSuccess(null);
      setBackendError(null);
      mutation.mutate({
        userEmail: values.email,
      });
    },
  });

  return (
    <Container>
      <Title>
        <FormattedMessage id="projects.add-external-user.title" />
      </Title>
      <FormattedMessage id="projects.add-external-user.info" />
      <Form onSubmit={formik.handleSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'projects.add-external-user.field-email-label',
          })}
          type="text"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />

        <Button
          type="submit"
          disabled={!(formik.isValid && formik.dirty) || mutation.isPending}
        >
          <FormattedMessage id="projects.add-external-user.submit" />
        </Button>
      </Form>
      <BackendMessage isError={!!backendError}>
        {backendError || backendSuccess}
      </BackendMessage>
    </Container>
  );
};

export default ExternalUserContainer;
