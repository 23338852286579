import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/Button';
import { EnrichedProject } from 'clients/projects/type';
import { ProjectVersion } from 'clients/projectVersions/type';
import Title from 'components/Title';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { migrateProject } from 'clients/projects/client';

import {
  Container,
  UpdateContainer,
  VersionsContainer,
  Text,
  Info,
} from './ProjectSettings.style';
import DeletionSettings from './components/DeletionSettings';
import InformationSettings from './components/InformationSettings';

interface Props {
  project: EnrichedProject;
  projectVersion: ProjectVersion;
}

const ProjectSettings: React.FC<Props> = ({ project, projectVersion }) => {
  const queryClient = useQueryClient();

  const migrateProjectMutation = useMutation({
    mutationFn: migrateProject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: reactQueryKeys.project(project.id),
      });
    },
  });

  return (
    <Container>
      <UpdateContainer>
        <Title>
          <FormattedMessage id="project.settings-tab.versions-settings.title" />
        </Title>
        {project.canMigrateToVersion ? (
          <>
            <VersionsContainer>
              <Text>
                <FormattedMessage
                  id="project.settings-tab.versions-settings.source-version"
                  values={{ version: projectVersion.version }}
                />
              </Text>
              <Text>
                <FormattedMessage
                  id="project.settings-tab.versions-settings.target-version"
                  values={{ version: project.canMigrateToVersion }}
                />
              </Text>
            </VersionsContainer>
            <div>
              <Info>
                <FormattedMessage id="project.settings-tab.versions-settings.info-1" />
              </Info>
              <Info>
                <FormattedMessage id="project.settings-tab.versions-settings.info-2" />
              </Info>
            </div>
            <Button
              onClick={() => {
                migrateProjectMutation.mutate({
                  projectId: project.id,
                  fromProjectVersionId: projectVersion.id,
                  toVersion: project.canMigrateToVersion,
                });
              }}
            >
              <FormattedMessage
                id="project.settings-tab.versions-settings.submit-button"
                values={{ version: project.canMigrateToVersion }}
              />
            </Button>
          </>
        ) : (
          <Text>
            <FormattedMessage id="project.settings-tab.versions-settings.up-to-date" />
          </Text>
        )}
      </UpdateContainer>
      <InformationSettings project={project} />
      <DeletionSettings project={project} />
    </Container>
  );
};

export default ProjectSettings;
