import { ProjectStats } from 'clients/admin/projects/entities/project-stats';

type ChartData = Record<number, number>;

export function computeChartData(projectsStats: ProjectStats[]) {
  const initChartData: ChartData = {};

  for (let i = 0; i <= 100; i++) {
    initChartData[i] = 0;
  }

  return projectsStats.reduce<ChartData>((scores, projectStats) => {
    scores[projectStats.score.score] += 1;

    return scores;
  }, initChartData);
}
