import React from 'react';

import { EnrichedProject } from 'clients/projects/type';
import { ProjectVersion } from 'clients/projectVersions/type';

import UsersSettings from './components/UsersSettings';
import ProjectSettings from './components/ProjectSettings';
import { Container } from './Settings.style';

interface Props {
  project: EnrichedProject;
  projectVersion: ProjectVersion;
}

const Settings: React.FC<Props> = ({ project, projectVersion }) => {
  return (
    <Container>
      <UsersSettings project={project} />
      <ProjectSettings project={project} projectVersion={projectVersion} />
    </Container>
  );
};

export default Settings;
