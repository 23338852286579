import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { PATHS, projectPathParams } from 'Router';
import BaseLayout from 'layout/BaseLayout';
import { fetchProjectVersion } from 'clients/projectVersions/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import {
  EnrichedProject,
  ProjectRoles,
  ProjectVersionMetadata,
} from 'clients/projects/type';

import {
  SecondHeader,
  HeaderLink,
  Tabs,
  Container,
  InformationList,
  Information,
  Warning,
  WarningIcon,
  InformationIcon,
} from './ProjectVersionPage.style';
import { getTabs } from './utils';

export enum FixedTabsIds {
  SUMMARY = 'summary',
  SETTINGS = 'settings',
}

interface Props {
  projectVersionMetadata: ProjectVersionMetadata;
  project: EnrichedProject;
  versionPicker: React.ReactElement;
}

const ProjectVersionPage: React.FC<Props> = ({
  projectVersionMetadata,
  project,
  versionPicker,
}) => {
  const { locale } = useLocale();
  const { tabId } = useParams() as projectPathParams;

  const projectVersionQuery = useQuery({
    queryKey: reactQueryKeys.projectVersion(projectVersionMetadata.id, locale),
    queryFn: () => fetchProjectVersion(projectVersionMetadata.id, locale),
  });

  if (!projectVersionQuery.isSuccess) {
    return <></>;
  }

  const projectVersion = projectVersionQuery.data;

  return (
    <BaseLayout
      title={project.name}
      secondHeader={
        <SecondHeader>
          <Tabs>
            {getTabs(projectVersion, project).map((tab) => (
              <HeaderLink
                key={tab.tabId}
                to={generatePath(PATHS.PROJECT, {
                  id: project.id,
                  tabId: tab.tabId,
                })}
                selected={tabId === tab.tabId}
              >
                {tab.text}
              </HeaderLink>
            ))}
          </Tabs>
          {versionPicker}
        </SecondHeader>
      }
    >
      <Container>
        {(!projectVersionMetadata.isLatestOfProject ||
          (!!project.canMigrateToVersion &&
            project.currentUserRole === ProjectRoles.ADMIN)) && (
          <InformationList>
            {!projectVersionMetadata.isLatestOfProject && (
              <Warning>
                <WarningIcon />
                <FormattedMessage id="project.information.old-version-displayed" />
              </Warning>
            )}
            {!!project.canMigrateToVersion &&
              project.currentUserRole === ProjectRoles.ADMIN && (
                <Information>
                  <InformationIcon />
                  <FormattedMessage id="project.information.new-version-available" />
                </Information>
              )}
          </InformationList>
        )}
        {
          getTabs(projectVersion, project).find((tab) => tabId === tab.tabId)
            ?.content
        }
      </Container>
    </BaseLayout>
  );
};

export default ProjectVersionPage;
