import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import TextField from 'components/TextField';
import Button from 'components/Button';
import Title from 'components/Title';
import { EnrichedProject, ProjectRoles } from 'clients/projects/type';
import { addUserToProject } from 'clients/projects/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';

import { BackendError, Container, Form } from './UsersNew.style';
import { AxiosError } from 'axios';
import Picker from 'components/Picker/Picker';

interface Props {
  project: EnrichedProject;
}

const UsersNew: React.FC<Props> = ({ project }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [backendError, setBackendError] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: addUserToProject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: reactQueryKeys.project(project.id),
      });
      formik.resetForm();
    },
    onError: async (error: AxiosError) => {
      const errorKey = (() => {
        switch (error.response?.status) {
          case 409:
            return 'project.settings-tab.users-settings.users-new.backend-error-409';
          case 404:
            return 'project.settings-tab.users-settings.users-new.backend-error-404';
          default:
            return 'project.settings-tab.users-settings.users-new.backend-error';
        }
      })();

      setBackendError(intl.formatMessage({ id: errorKey }));
    },
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: 'project.settings-tab.users-settings.users-new.field-email-error',
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      projectRole: ProjectRoles.EDITOR,
    },
    validationSchema,
    onSubmit: (values) => {
      setBackendError(null);
      mutation.mutate({
        projectId: project.id,
        userEmail: values.email,
        projectRole: values.projectRole,
      });
    },
  });

  return (
    <Container>
      <Title>
        <FormattedMessage id="project.settings-tab.users-settings.add-user" />
      </Title>
      <Form onSubmit={formik.handleSubmit}>
        <TextField
          label={intl.formatMessage({
            id: 'project.settings-tab.users-settings.users-new.field-email-label',
          })}
          type="text"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />
        <Picker
          pickedValue={formik.values.projectRole}
          values={Object.values(ProjectRoles)}
          onValuePicked={(value) => formik.setFieldValue('projectRole', value)}
        />
        <Button
          type="submit"
          disabled={!(formik.isValid && formik.dirty) || mutation.isPending}
        >
          <FormattedMessage id="project.settings-tab.users-settings.users-new.add" />
        </Button>
      </Form>
      <BackendError>{backendError}</BackendError>
    </Container>
  );
};

export default UsersNew;
