export type UserBackend = {
  id: string;
  auth0Id: string;
  email: string;
  roles: string[];
};

export enum Roles {
  SUPER_ADMIN = 'superAdmin',
  PADOKIEN = 'padokien',
  EXTERNAL = 'external',
}

export class User {
  id: string;
  auth0Id: string;
  email: string;
  private roles: string[];

  constructor(userBackend: UserBackend) {
    this.id = userBackend.id;
    this.auth0Id = userBackend.auth0Id;
    this.email = userBackend.email;
    this.roles = userBackend.roles;
  }

  isAtLeastPadokien(): boolean {
    return (
      this.roles.includes(Roles.PADOKIEN) ||
      this.roles.includes(Roles.SUPER_ADMIN)
    );
  }
}
