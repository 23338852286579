import React from 'react';
import { useIntl } from 'react-intl';
import {
  Root,
  Value,
  Icon,
  Portal,
  Group,
  ItemText,
} from '@radix-ui/react-select';

import {
  ChevronDownIcon,
  Container,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectViewport,
} from './ScoreFilter.style';
import { Input } from './ScoreFilter.style';

interface Props {
  scoreOperator: string | undefined;
  onScoreOperatorPicked: (value: string) => void;
  scoreValue: string | undefined;
  setScoreValue: (value: string) => void;
}

const ScoreFilter = ({
  scoreOperator,
  onScoreOperatorPicked,
  scoreValue,
  setScoreValue,
}: Props) => {
  const intl = useIntl();

  const values = ['<', '<=', '>', '>=', '='];

  return (
    <Container>
      <Root value={scoreOperator} onValueChange={onScoreOperatorPicked}>
        <SelectTrigger className="SelectTrigger">
          <Value
            placeholder={intl.formatMessage({
              id: 'admin.table-component.score-filter',
            })}
          />
          <Icon>
            <ChevronDownIcon />
          </Icon>
        </SelectTrigger>
        <Portal>
          <SelectContent position="popper">
            <SelectViewport>
              <Group>
                {values.map((val) => (
                  <SelectItem key={val} value={val}>
                    <ItemText>{val}</ItemText>
                  </SelectItem>
                ))}
              </Group>
            </SelectViewport>
          </SelectContent>
        </Portal>
      </Root>
      <Input
        type="number"
        min={0}
        max={100}
        value={scoreValue}
        onChange={(e) => setScoreValue(e.target.value)}
      />
    </Container>
  );
};

export default ScoreFilter;
