import React from 'react';

import { CheckboxIcon, Container, Tooltip } from './Checkbox.style';

interface Props {
  isChecked: boolean;
  tooltipText?: string;
}

const Checkbox: React.FC<Props> = ({ isChecked, tooltipText }) => {
  return (
    <Container>
      <CheckboxIcon $isChecked={isChecked} />
      {tooltipText && <Tooltip>{tooltipText}</Tooltip>}
    </Container>
  );
};

export default Checkbox;
