import React, { ReactNode } from 'react';

import sadAvocado from 'assets/sad-avocado.png';

import { Container, Image, Text } from './BaseError.style';

interface Props {
  error?: string;
  action?: ReactNode;
}

const BaseError: React.FC<Props> = ({ error, action }) => {
  return (
    <Container>
      <Image src={sadAvocado} alt="" />
      <Text>{error ?? 'An error occurred'}</Text>
      {action}
    </Container>
  );
};

export default BaseError;
