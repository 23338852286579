import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as RoseLogo } from 'assets/rose.svg';

import {
  colors,
  font,
  getSpacing,
  onLargeScreen,
  onSmallScreen,
  outline,
} from 'stylesheet';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Header = styled.div`
  height: ${getSpacing(6)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray0};
  flex-shrink: 0;

  ${onLargeScreen(css`
    padding: 0 ${getSpacing(5)};
  `)}

  ${onSmallScreen(css`
    padding: 0 ${getSpacing(2)};
  `)}
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${getSpacing(2)};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSpacing(0.5)};
  ${outline()}
`;

export const Logo = styled(RoseLogo)`
  height: ${getSpacing(4)};
  color: ${colors.purple};
  cursor: pointer;
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-start;
  column-gap: ${getSpacing(1)};

  ${onLargeScreen(css`
    ${font.size16}
  `)}

  ${onSmallScreen(css`
    ${font.size14}
  `)}
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${getSpacing(2)};
`;

export const Gutter = styled.div`
  margin: auto;
  max-width: ${getSpacing(192)};
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
`;

export const Content = styled.div`
  box-sizing: border-box;
  height: 100%;

  ${onSmallScreen(css`
    padding: ${getSpacing(2)};
  `)}

  ${onLargeScreen(css`
    padding: ${getSpacing(2)} ${getSpacing(8)} ${getSpacing(5)};
  `)}
`;
