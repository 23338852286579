import React, { ReactNode } from 'react';

import { ProjectVersion } from 'clients/projectVersions/type';
import { EnrichedProject, ProjectRoles } from 'clients/projects/type';
import { FormattedMessage } from 'react-intl';

import { FixedTabsIds } from './ProjectVersionPage';
import SummaryTab from './components/SummaryTab';
import CategoryTab from './components/CategoryTab';
import Settings from './components/Settings';

type Tab = {
  tabId: string;
  text: ReactNode;
  content: ReactNode;
};

export const getTabs = (
  projectVersion: ProjectVersion,
  project: EnrichedProject,
): Tab[] => [
  {
    tabId: FixedTabsIds.SUMMARY,
    text: <FormattedMessage id={`project.tab-summary`} />,
    content: <SummaryTab projectVersion={projectVersion} />,
  },
  ...projectVersion.categories.map((category) => ({
    tabId: category.key,
    text: <>{category.name}</>,
    content: (
      <CategoryTab
        projectVersionId={projectVersion.id}
        category={category}
        project={project}
      />
    ),
  })),
  ...(project.currentUserRole === ProjectRoles.ADMIN
    ? [
        {
          tabId: FixedTabsIds.SETTINGS,
          text: <FormattedMessage id={`project.tab-settings`} />,
          content: (
            <Settings project={project} projectVersion={projectVersion} />
          ),
        },
      ]
    : []),
];
