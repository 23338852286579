import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import TableTab from './components/TableTab';
import ChartTab from './components/ChartTab';

type Tab = {
  tabId: string;
  text: ReactNode;
  content: ReactNode;
};

export enum AdminFixedTabsIds {
  DASHBOARD = 'dashboard',
  CHART = 'chart',
}

export const getTabs = (): Tab[] => [
  {
    tabId: AdminFixedTabsIds.DASHBOARD,
    text: <FormattedMessage id={`admin.tab-${AdminFixedTabsIds.DASHBOARD}`} />,
    content: <TableTab />,
  },
  {
    tabId: AdminFixedTabsIds.CHART,
    text: <FormattedMessage id={`admin.tab-${AdminFixedTabsIds.CHART}`} />,
    content: <ChartTab />,
  },
];

export function capitalize(word: string) {
  if (word.length === 0) {
    return word;
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function roundToDecimal(number: number, digit = 2): number {
  return parseFloat(number.toFixed(digit));
}
