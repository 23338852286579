import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';

import BaseError from 'pages/Errors/BaseError';
import Button from 'components/Button';

interface Props {
  error?: string;
}

const AuthenticationError: React.FC<Props> = ({ error }) => {
  const { logout } = useAuth0();
  return (
    <BaseError
      error={error ?? 'An authentication or authorization error occurred'}
      action={
        <Button onClick={() => logout()}>
          <FormattedMessage id="error.logout" />
        </Button>
      }
    />
  );
};

export default AuthenticationError;
