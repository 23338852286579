import { Achievement } from 'clients/projectVersions/type';
import { patch, get } from 'clients/client';
import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import { UpdatedAchievement } from './type';
type CommentPayload = {
  text: string;
  id: string;
};

export async function comment({
  text,
  id,
}: CommentPayload): Promise<Achievement> {
  const response = await patch<
    Achievement,
    {
      comment: string;
    }
  >(`${process.env.REACT_APP_BACKEND_URL}/achievements/${id}/comment`, {
    comment: text,
  });

  return response.data;
}

export async function toggleDone(id: string): Promise<void> {
  await patch(
    `${process.env.REACT_APP_BACKEND_URL}/achievements/${id}/toggle-done`,
  );
}

export async function toggleTarget(id: string): Promise<void> {
  await patch(
    `${process.env.REACT_APP_BACKEND_URL}/achievements/${id}/toggle-target`,
  );
}

export async function fetchLastUpdatedAchievements(
  locale: Locales,
  projectVersionId: string,
): Promise<UpdatedAchievement[]> {
  const response = await get<UpdatedAchievement[]>(
    `${process.env.REACT_APP_BACKEND_URL}/achievements`,
    { params: { locale, projectVersionId } },
  );

  return response.data;
}
