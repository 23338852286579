import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import Auth0Handler from './components/Auth0Handler';
import UserProvider from './components/UserProvider';

interface Props {
  children: ReactNode;
}

const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_BACKEND_URL || '',
        scope: process.env.REACT_APP_AUTH0_API_SCOPE || '',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Auth0Handler>
        <UserProvider>{children}</UserProvider>
      </Auth0Handler>
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
