import React, { useState } from 'react';

import Checkbox from 'components/Checkbox';

import { Button } from './EditableCheckbox.style';

interface Props {
  toggleChecked: () => void;
  isInitiallyChecked: boolean;
  tooltipText?: string;
}

const EditableCheckbox: React.FC<Props> = ({
  toggleChecked,
  isInitiallyChecked,
  tooltipText,
}) => {
  const [isChecked, setIsChecked] = useState(isInitiallyChecked);

  return (
    <Button
      onClick={() => {
        setIsChecked((isChecked) => !isChecked);
        toggleChecked();
      }}
    >
      <Checkbox isChecked={isChecked} tooltipText={tooltipText} />
    </Button>
  );
};

export default EditableCheckbox;
