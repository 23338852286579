import React, { useState, useEffect, ReactElement } from 'react';
import _orderBy from 'lodash.orderby';

import {
  StyledTable,
  Tbody,
  Thead,
  Tr,
  Th,
  ThButton,
  Td,
  ChevronDownIcon,
  ChevronUpIcon,
  ButtonLink,
} from './Table.style';
import { FlattenSimpleInterpolation } from 'styled-components';

interface Header {
  key: string;
  label: string;
}

export type Data = Record<
  string,
  {
    value: string | number | Date | null;
    label: string | number;
  }
>;

interface Props {
  headers: Header[];
  data: Data[];
  gridTemplateColumnsCss: FlattenSimpleInterpolation;
  iconLink?: {
    goTo: (item: Data) => string;
    iconElement: ReactElement;
  };
}

const TableComponent: React.FC<Props> = ({
  headers,
  data,
  gridTemplateColumnsCss,
  iconLink,
}) => {
  const [orderBy, setOrderBy] = useState<keyof Data>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [displayedData, setDisplayedData] = useState<Data[]>([]);

  const toggleOrder = ({ key }: Pick<Header, 'key'>) => {
    const isAsc = orderBy === key && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(key);
  };

  const handleSort = ({ key }: Pick<Header, 'key'>, data: Data[]) => {
    if (orderBy === '') {
      setDisplayedData(data);
    } else {
      const sorted = _orderBy(data, (o) => o[key].value, order);
      setDisplayedData(sorted);
    }
  };

  useEffect(() => {
    handleSort({ key: orderBy }, data);
  }, [data, orderBy, order]);

  return (
    <StyledTable>
      <Thead>
        <Tr gridTemplateColumnsCss={gridTemplateColumnsCss}>
          {headers.map(({ key, label }) => (
            <Th key={key}>
              <ThButton onClick={() => toggleOrder({ key })}>
                <span>{label}</span>
                {orderBy === key &&
                  (order === 'asc' ? <ChevronUpIcon /> : <ChevronDownIcon />)}
              </ThButton>
            </Th>
          ))}
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {displayedData.map((item, index) => (
          <Tr key={index} gridTemplateColumnsCss={gridTemplateColumnsCss}>
            {headers.map(({ key }) => (
              <Td key={`${key}-${index}`}>{item[key].label}</Td>
            ))}

            {iconLink ? (
              <Td>
                <ButtonLink to={iconLink.goTo(item)}>
                  {iconLink.iconElement}
                </ButtonLink>
              </Td>
            ) : null}
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};

export default TableComponent;
