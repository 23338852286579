import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from 'Router';
import { BrowserRouter } from 'react-router-dom';

import LocaleProvider from 'providers/LocaleProvider';
import AuthenticationProvider from 'providers/AuthenticationProvider';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <LocaleProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AuthenticationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LocaleProvider>
  );
}

export default App;
