import { Locales, useLocale } from 'providers/LocaleProvider/LocaleProvider';
import React from 'react';
import Picker from 'components/Picker/Picker';

const LocalePicker: React.FC = () => {
  const { locale, setLocale } = useLocale();

  return (
    <Picker
      pickedValue={locale}
      onValuePicked={(value) => {
        setLocale(value as Locales);
      }}
      values={Object.values(Locales)}
    />
  );
};

export default LocalePicker;
