import styled from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';
import * as Select from '@radix-ui/react-select';

import { ReactComponent as chevronDown } from 'assets/chevron-down.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.gray0};
  border-radius: ${getSpacing(0.5)};
  overflow: hidden;
  ${outline()};
`;

export const ChevronDownIcon = styled(chevronDown)`
  height: ${getSpacing(1.5)};
  color: ${colors.black};
`;

export const SelectTrigger = styled(Select.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: ${getSpacing(4)};
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  gap: ${getSpacing(1)};
  background-color: ${colors.white};
  color: ${colors.black};
  ${font.size16};

  &[data-placeholder] {
    color: ${colors.black};
  }
`;

export const SelectContent = styled(Select.Content)`
  overflow: hidden;
  background-color: white;
  border-radius: ${getSpacing(0.5)};
  border: 1px solid ${colors.gray_1};
  width: ${getSpacing(9)};
`;

export const SelectViewport = styled(Select.Viewport)`
  padding: ${getSpacing(1)} 0;
`;

export const SelectItem = styled(Select.SelectItem)`
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${getSpacing(3)};
  padding: 0 ${getSpacing(4)} 0 ${getSpacing(3)};
  position: relative;
  user-select: none;
  outline: none;
  ${font.size14};

  &[data-highlighted][data-state] {
    background-color: ${colors.gray_1};
  }

  &[data-state='checked'] {
    background-color: ${colors.gray_2};
  }
`;

export const Input = styled.input`
  display: block;
  height: ${getSpacing(4)};
  width: ${getSpacing(7)};
  background-color: ${colors.gray_2};
  padding: 0 ${getSpacing(1)};
  ${font.size16};
`;
