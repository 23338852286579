import styled, { css } from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';
import { Link } from 'react-router-dom';

export const ButtonStyle = css`
  cursor: pointer;
  box-sizing: border-box;
  width: fit-content;
  height: ${getSpacing(4)};
  color: ${colors.purple};
  border: 1px solid ${colors.purple};
  border-radius: ${getSpacing(0.5)};
  padding: 0 ${getSpacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${font.size16};
  ${outline()}
`;

export const SecondaryButtonStyle = css`
  ${ButtonStyle};
  color: ${colors.white};
  border: 1px solid ${colors.purple};
  background-color: ${colors.purple};
`;

export const ButtonLink = styled(Link)`
  ${ButtonStyle};
`;

export const SecondaryButtonLink = styled(Link)`
  ${SecondaryButtonStyle};
`;

const Button = styled.button`
  ${ButtonStyle};

  &:disabled {
    border: 1px solid ${colors.gray1};
    color: ${colors.gray1};
  }
`;

export default Button;
