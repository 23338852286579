import { get, put } from 'clients/client';
import { User, UserBackend } from './type';

export async function fetchOrCreateUser(): Promise<User> {
  const response = await put<UserBackend>(
    `${process.env.REACT_APP_BACKEND_URL}/users`,
  );

  return new User(response.data);
}

export async function fetchUser(): Promise<User> {
  const response = await get<UserBackend>(
    `${process.env.REACT_APP_BACKEND_URL}/users`,
  );

  return new User(response.data);
}
