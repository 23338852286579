import { ValueLabel } from 'clients/utils';
import { ProjectStats } from 'clients/admin/projects/entities/project-stats';
import {
  calculateAverage,
  calculateMedian,
  calculateStandardDeviation,
  min,
  max,
} from './math';
import { capitalize } from 'pages/Admin/utils';

export function mapDataToTable(
  projectsStats: ProjectStats[],
): Record<string, ValueLabel>[] {
  const scores: {
    globalScores: number[];
    scoresByCategory: Record<string, number[]>;
    complianceRunScores: number[];
  } = {
    globalScores: [],
    scoresByCategory: {
      resilient: [],
      operable: [],
      secure: [],
      empowering: [],
    },
    complianceRunScores: [],
  };

  const { globalScores, scoresByCategory, complianceRunScores } =
    projectsStats.reduce((acc, projectsStat) => {
      acc.globalScores.push(projectsStat.score.score);
      acc.complianceRunScores.push(projectsStat.score.runScore);
      const categories = projectsStat.score.categories;

      Object.entries(categories).forEach(([key, { score }]) => {
        acc.scoresByCategory[key].push(score);
      });

      return acc;
    }, scores);

  return [
    generateRow('Global', 'global-score', globalScores),
    generateRow('Compliance run', 'compliance-run', complianceRunScores),
    ...Object.entries(scoresByCategory).map(([category, scoreByCategory]) =>
      generateRow(category, capitalize(category), scoreByCategory),
    ),
  ];
}

function generateRow(label: string, value: string, scores: number[]) {
  return {
    score: {
      value,
      label: capitalize(label),
    },
    average: {
      value: calculateAverage(scores),
      label: calculateAverage(scores),
    },
    min: {
      value: min(scores),
      label: min(scores),
    },
    max: {
      value: max(scores),
      label: max(scores),
    },
    median: {
      value: calculateMedian(scores),
      label: calculateMedian(scores),
    },
    standardDeviation: {
      value: calculateStandardDeviation(scores),
      label: calculateStandardDeviation(scores),
    },
  };
}
