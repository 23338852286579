import React, { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

import MarkdownContainer from 'components/MarkdownContainer';
import { Container, InnerContainer, TextArea } from './Comment.style';
import { useComment } from './hooks/useComment';

interface Props {
  initialComment: string | null;
  achievementId: string;
  projectVersionId: string;
  isEditable: boolean;
}

const Comment: React.FC<Props> = ({
  initialComment,
  achievementId,
  projectVersionId,
  isEditable,
}) => {
  const [comment, setComment] = useState<string>(initialComment ?? '');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const mutation = useComment(projectVersionId);

  // useCallback required on the debounce so that the comment state update doesn't cause the function to be recreated during re-render.
  const debouncedMutation = useCallback(
    debounce(
      (text: string) => mutation.mutate({ text, id: achievementId }),
      700,
    ),
    [],
  );

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        if (e.target instanceof HTMLElement && e.target.nodeName === 'A') {
          const href = Array.from(e.target.attributes).find(
            (attr) => attr.name === 'href',
          );
          if (href?.value) {
            window.open(href.value, '_blank');
          }
        }

        setIsEditing(true);
      }}
    >
      {isEditable ? (
        isEditing ? (
          <TextArea
            value={comment}
            autoFocus
            onChange={(e) => {
              setComment(e.target.value);
              debouncedMutation(e.target.value);
            }}
            onBlur={() => setIsEditing(false)}
          />
        ) : (
          <InnerContainer>
            <MarkdownContainer content={comment} />
          </InnerContainer>
        )
      ) : (
        <InnerContainer>
          <MarkdownContainer content={comment} />
        </InnerContainer>
      )}
    </Container>
  );
};

export default Comment;
