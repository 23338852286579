import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';

import { PATHS, projectPathParams } from 'Router';
import BaseLayout from 'layout/BaseLayout';

import { Container, HeaderLink, SecondHeader, Tabs } from './AdminPage.style';
import { getTabs } from './utils';

const AdminPage: React.FC = () => {
  const intl = useIntl();
  const { tabId } = useParams() as projectPathParams;

  return (
    <BaseLayout
      title={intl.formatMessage({ id: 'admin.title' })}
      secondHeader={
        <SecondHeader>
          <Tabs>
            {getTabs().map((tab) => (
              <HeaderLink
                key={tab.tabId}
                to={generatePath(PATHS.ADMIN, {
                  tabId: tab.tabId,
                })}
                selected={tabId === tab.tabId}
              >
                {tab.text}
              </HeaderLink>
            ))}
          </Tabs>
        </SecondHeader>
      }
    >
      <Container>
        {getTabs().find((tab) => tabId === tab.tabId)?.content}
      </Container>
    </BaseLayout>
  );
};

export default AdminPage;
